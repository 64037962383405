import React from "react";
import { Link } from "react-router-dom";

const Copyright = () => {
  return (
    <section
      id="copyright"
      className="container-fluid copyright py-4 bg-black text-light"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-center text-md-start mb-3 mb-md-0 p-0">
            <div className="d-sm-flex flex-row justify-content-center">
              <p className="link">
                {/* <Link
                  className="text-decoration-none"
                  to="/privacy-policy"
                  onClick={() => {
                    window.scrollTo({top: 0, left: 0, behavior: "instant"});
                  }}
                >
                  Privacy Policy
                </Link> */}
                <a
                  className="text-decoration-none"
                  href="/policies/privacy-policy"
                  aria-label="Privacy policy"
                >
                  Privacy Policy
                </a>
              </p>
              <p className="link border-0">
                <a
                  className="text-decoration-none"
                  href="/policies/terms-of-use"
                  aria-label="Terms of use"
                >
                  Terms of use
                </a>
              </p>
              {/* <p className="link border-0">
                <Link
                  className="text-decoration-none"
                  to="/sitemap.xml"
                  onClick={() => {
                    window.scrollTo({top: 0, left: 0, behavior: "instant"});
                  }}
                >
                  Sitemap
                </Link>
              </p> */}
            </div>
          </div>
          <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
            &copy; Copyright 2023 - {new Date().getFullYear()}&nbsp;
            <a
              className="text-decoration-none"
              href="/"
              aria-label="Softaim Technologies Pvt. Ltd."
            >
              Softaim Technologies Pvt. Ltd.
            </a>{" "}
            All rights reserved.
          </div>
        </div>
      </div>
    </section>
  );
};

export default Copyright;
